import axios from "axios";
import serverConf from "config/server.config";

const instance = axios.create({
  baseURL: serverConf.HOST,
  /*
  headers: {
    //"Content-Type": "application/json",
    "Content-Type": "multipart/form-data",
  },
  */
});

instance.interceptors.request.use(
  (config) => {
    if (config.isMultipart) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

export default instance;